.evaluator-table-header,
.evaluatorTab .report-header {
  display: grid;
  grid-template-columns: 0.06fr 1.2fr 1.5fr 1fr 0.4fr 150px 1.2fr;
}
.evaluator-accordion-row {
  display: grid;
  grid-template-columns: 1fr;
}

.evaluatorTab .dashboard-accordion {
  max-width: 100% !important;
  background-color: white !important;
}
.evaluatorTab .accordion-summary {
  padding: 0;
}
.evaluatorTab .report-header {
  /* display: grid;
  grid-template-columns: 0.06fr 1fr 1.5fr 1fr 0.6fr 150px 1fr; */
  overflow-wrap: anywhere;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  color: black !important;
}

.evaluatorTab .accord-td {
  /* border: 1px solid black; */
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
}
.evaluatorTab .evaluator-progress {
  max-width: 110px;
  margin: 0 auto;
}
.evaluatorTab .evaluator-progress progress {
  max-width: 100%;
}
.evaluatorTab .accordion-details {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.evaluatorTab .accordion-details-row {
  display: grid;
  grid-template-columns: 0.1fr 0.6fr 1fr 1.5fr 1fr 0.5fr 0.3fr;
  border: 1px solid var(--grey);
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  /* text-align: left; */
}
.evaluatorTab .accordion-details-header {
  border: none;
  margin-bottom: 10px;
}
.evaluator-status-icon svg {
  background-color: var(--gold);
  border-radius: 50%;
  color: white;
}
.evaluator-status-text {
  font-size: 0.8rem;
}
.evaluator-actions {
  display: flex;
}
.evaluator-edit {
  margin-right: 10px;
  max-width: max-content;
}
.evaluator-delete {
  max-width: max-content;
}
.evaluator-delete svg {
  color: var(--orange);
}

.evaluators-datas .evaluator-name {
  text-align: left;
}

.accordion-details-header {
  font-weight: 600;
}

@media screen and (max-width: 1024px) {
  .table-rows,
  .evaluator-table-header {
    min-width: 800px;
    align-items: center;
  }
  .evaluatorTab .accordion-details-row,
  .evaluator-table-header,
  .evaluatorTab .report-header {
    grid-template-columns: 40px 0.6fr 1fr 200px 100px 0.5fr 150px;
    min-width: 1000px;
    align-items: center;
    font-size: 12px;
  }
  .evaluatorTab .accord-td {
    font-size: 12px;
  }
}
