.campaign__Title {
  background-color: var(--gold);
  color: white;
  font-size: 2rem;
  padding: 10px 20px;
}
.campaign__Content {
  max-width: 1000px;
  display: block;
  margin: 0px auto;
}
.campaign__Header {
  margin: 20px 0px 10px;
  font-size: 1.5rem;
  font-weight: 600;
}
.campaign__Header > span {
  color: var(--gold);
}
.campaign__Subtitle {
  /* margin-left: 0px; */
  margin-bottom: 20px;
  font-weight: 500;
}
.campaign__Subtitle > span {
  color: var(--gold);
}
.campaign__Report {
  border: 1px solid var(--grey);
  border-radius: 3px;
  box-shadow: 1px 1px 4px white, 1px 1px 4px rgba(0, 0, 0, 0.6);
  padding: 10px 20px 30px;
  max-width: 800px;
  margin-left: 30px;
  margin-top: 30px;
}
.report-status {
  margin-bottom: 20px;
}
.report-body {
  display: grid;
  grid-template-columns: 1fr 7fr 2fr;
}
.report-icon {
  background-color: var(--gold);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.report-icon svg {
  color: white;
  font-size: 40px;
}
.report-date {
  align-self: flex-end;
  opacity: 0.7;
}
.report-download {
  align-self: flex-end;
}
.open-task {
  margin-top: 40px;
  width: max-content;
  padding: 10px;
  border-radius: 3px;
  color: white;
  font-weight: 600px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  background-color: var(--gold);
}
.open-task-text {
}
.open-task hr {
  display: block;
  color: aqua;
  background-color: blue;
}
.campaign__Task {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 9fr;
  /* justify-content: center; */
  align-items: center;
}
.progress-circle {
  height: 65px;
  width: 65px;
  border: 1px solid var(--grey);
  border-radius: 50%;
}
.progress-task {
  /* height: 60px; */
  max-width: 650px;
  border: 1px solid var(--grey);
}
.campaign__Evaluator {
  margin-top: 60px;
  max-width: 900px;
  padding-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .campaign__Header {
    text-align: center;
  }
  .campaign__Subtitle {
    text-align: center;
  }
  .campaign__Report {
    margin-left: 0;
    /* padding-left: 0; */
  }
  .campaign .report-body {
    grid-template-columns: 1fr 2fr 2fr;
  }
  .campaign .campaign__Task {
    padding: 0 10px;
  }
  .campaign .progress-circle {
    height: 45px;
    width: 45px;
  }
}
