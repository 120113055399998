.title {
  text-align: center;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 20px;
}
.accordions {
  max-width: 1000px;
  display: block;
  margin-inline: auto;
}
