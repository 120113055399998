.login__Form {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  position: relative;
}
.login__Body {
  /* display: grid; */
  /* min-width: 100%; */
  align-items: center;
  justify-content: center;
  /* min-height: 70vh; */
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  margin-top: 30px;
}
.login__Form {
  /* min-height: 70vh; */
  max-width: 1000px;
  border: 1px solid var(--grey);
  box-shadow: 1px 1px 4px 1px rgba(104, 101, 101, 0.5);
  margin: 0 auto;
  /* margin-top: 30px; */
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  align-items: center;
  justify-content: center;
}

.login__Left {
  /* max-width: 800px; */
  margin: 0 auto;
  /* min-height: 70vh; */
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
h2.sign-in {
  margin-bottom: 20px;
  font-weight: 500;
  color: var(--blue);
  font-size: 1.5rem;
  align-self: flex-start;
}
h3.sign-in {
  font-weight: 500;
  color: var(--blue);
  font-size: 1.2rem;
}

.login-formik {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
}
.login label {
  color: var(--grey);
  font-weight: 500;
}
.login-formik input {
  border: 1px solid var(--grey);
  border-radius: 5px;
  width: 300px;
  height: 40px;
  padding: 0 10px;
}
.login-formik input::placeholder {
  /* padding: 10px; */
}
.forgot-password {
  margin: 10px 0;
  color: var(--grey);
  display: block;
  align-self: flex-start;
  max-width: max-content;
}

.login-submit {
  background-color: var(--gold);
  padding: 10px;
  color: white;
  border-radius: 5px;
}
.login__Right {
  /* border: 1px solid blue; */
  /* height: 400px; */
  width: 100%;
  align-self: flex-end;
}

.login__Right img {
  max-width: 100%;
  max-height: 100%;
  align-self: flex-end;
}

@media screen and (max-width: 600px) {
  .login__Form {
    grid-template-columns: 1fr;
  }
  .login__Left {
    padding: 20px 10px;
  }
  .login__Right {
    display: none;
  }
}
.change-password-title {
  color: var(--blue);
  margin-bottom: 20px;
}
