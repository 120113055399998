.feedback__Title {
  background-color: var(--gold);
  color: white;
  font-size: 2rem;
  padding: 10px 20px;
}
.feedback__Header {
  margin: 20px 20px 10px;
  font-size: 1.5rem;
  font-weight: 600;
}
.feedback__Header > span {
  color: var(--gold);
}
.feedback__Notification {
  background-color: var(--pink);
  border-radius: 10px;
  box-shadow: 4px 4px 4px var(--pink), 4px 4px 4px white;
  padding: 20px;
  margin-top: 30px;
  max-width: 700px;
  margin-inline: 2rem;
}
.feedback-notify {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 21fr;
}
.feedback-notify-icon {
  color: var(--orange);
}
.feedback-notify > p {
  opacity: 0.7;
}
.feedback-link {
  display: block;
  max-width: max-content;
  background-color: var(--orange);
  color: white;
  padding: 10px;
  border-radius: 5px;
}
