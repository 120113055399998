.thankyou {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.thankyou__Content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1000px;
  align-items: center;
  border: 1px solid var(--grey);
}
.thankyou__Left {
  padding: 20px;
}
.thankyou__Left h2 {
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 600;
}

.thankyou__Left p {
  line-height: 25px;
  /* font-size: 1.2rem; */
}

@media screen and (max-width: 600px) {
  .thankyou__Content {
    grid-template-columns: 1fr;
    text-align: center;
  }
}
