@media screen and (max-width: 767px) {
  .participant {
    max-width: 100%;
    overflow: hidden;
  }
  .participant__Sidebar {
    display: none;
  }
}
@media screen and (min-width: 425px) and (max-width: 767px) {
  .participant__Content {
    padding: 3%;
  }
}
