@media screen and (max-width: 767px) {
  .coach {
    max-width: 100%;
    overflow: hidden;
  }
  .coach__SideBar {
    display: none !important;
  }
}
@media screen and (min-width: 425px) and (max-width: 767px) {
  .coach__Content {
    /* padding: 3%; */
  }
}
