.landingPage {
  position: relative;
}
.landingPage .link {
  max-width: max-content;
  margin-inline: auto;
}
.landingPage__Header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-inline: 100px;
  padding-left: 0;
  position: absolute;
  width: 100%;
}
.header-left {
  padding: 10px;
  padding-bottom: 30px;
  max-width: 300px;
  background-color: white;
  border-bottom-right-radius: 200px;
}
.header-logo {
  height: 60px;
  /* width: 80px; */
  display: flex;
  align-items: center;
  align-content: center;
  font-weight: 600;
  /* border: 1px solid black; */
}
.header-right {
  justify-self: right;
  margin-top: 15px;
}
.header-link {
  background-color: var(--gold);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
}
.landingPage__Hero {
  /* background: url("/public/assets/landing-page-bg.JPG") no-repeat; */
  background-color: var(--hero-bg);
  background-size: cover;
  /* padding-top: 100px; */
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  align-items: center;
}
.hero-left {
  margin-left: 160px;
}
.hero-content {
  max-width: 800px;
}
.hero-content h2 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 50px;
  justify-self: right;
}
.hero-right-align {
  margin-top: 10px !important;
  text-align: right;
  font-weight: 1.5rem;
  margin-bottom: 20px !important;
}
.hero-content p {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 20px;
  /* text-align: center; */
}
.hero-link {
  background-color: var(--orange);
  color: white;
  width: max-content;
  padding: 10px 30px;
  border-radius: 10px;
  display: block;
  margin: 30px auto 10px;
}
.hero-image {
  /* width: 100%; */
  /* border: 1px solid black; */
  /* height: 400px; */
}
.landingPage__Partners {
  text-align: center;
  padding: 30px 0;
  display: none;
}
.partner-image-list {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-logo {
  height: 40px;
  width: 160px;
  border: 1px solid black;
  margin-inline: 10px;
}

.landingPage__Services {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 100px;
  padding: 30px 0;
  background-color: var(--service-bg);
}
.service-image {
  width: 100%;
}
.services-right {
  padding-right: 80px;
}
.services-right h3 {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 20px;
}
.services-right ul {
  font-weight: 600;
}
.services-right li {
  margin-top: 15px;
}

.landingPage__Benefit {
  padding: 50px 0;
}
.landingPage__Benefit > h3 {
  text-align: center;
  font-weight: 800;
  font-size: 2rem;
  padding: 20px 0;
}
.benefit-cards {
  display: flex;
  justify-content: center;
}
.benefit-card {
  margin-inline: 50px;
}
.benefit-card-image {
  /* border: 1px solid black; */
  /* height: 200px; */
  display: block;
  margin: 0 auto;
  width: 300px;
}
.benefit-card-title {
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 10px;
  line-height: 30px;
  height: 70px;
  /* margin-bottom: 20px; */
  text-align: center;
}
.landingPage__Experience {
  background-color: var(--experience-bg);
  padding: 30px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.experience-left {
  margin-left: 130px;
}
.experience-title,
.extra-title {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 20px;
}
.experience-summary,
.extra-summary {
  line-height: 35px;
  font-size: 1.3rem;
}
.experience-image {
  /* height: 350px;
  width: 100%;
  border: 1px solid black; */
}

.landingPage__Extra {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.extra-left {
  margin-right: 60px;
}
.extra-image {
  /* width: 100%;
  height: 350px;
  border: 1px solid black; */
}
.extra-title {
  margin-bottom: 10px;
}
.extra-right-align {
  font-weight: 600;
  font-size: 1.4rem;
  font-style: italic;
  padding-left: 30px;
  margin-bottom: 30px;
}
.extra-summary {
  margin-bottom: 30px;
}
.landingPage__Footer {
  display: grid;
  grid-template-columns: 2fr 1.4fr 1fr;
  padding: 40px 60px;
  background-color: var(--footer-bg);
}
.footer-logo {
  max-width: 300px;
}
.footer-center {
  display: flex;
  /* font-size: 0.8rem; */
}
.footer-links {
  margin-right: 35px;
  width: max-content;
  font-weight: 600;
  font-size: 1rem;
}
.footer-link {
  margin-bottom: 10px;
}
.footer-right {
  align-self: end;
}
.footer-social {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1px;
  color: var(--green);
}
.footer-social a {
  max-width: max-content;
}
.footer-social svg {
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .landingPage {
    max-width: 100%;
    /* overflow: hidden; */
  }
  .landingPage__Header {
    padding-inline: 0;
    grid-template-columns: 2fr 1fr;
  }
  .header-left {
    max-width: 100%;
  }
  .header-right {
    /* justify-self: right; */
    /* border: 1px solid black; */
  }
  .landingPage__Hero {
    padding-top: 100px;
    grid-template-columns: 1fr;
  }
  .hero-left {
    margin: 0;
  }
  .hero-content h2 {
    text-align: center;
    font-size: 1.6rem;
  }
  .hero-right-align {
    font-size: 1.2rem !important;
  }
  .hero-left p {
    padding-inline: 10px;
  }
  .landingPage__Services {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .services-right {
    padding-inline: 10px;
    margin-top: 10px;
  }
  .landingPage__Benefit h3 {
    padding: 10px;
    font-size: 1.3rem;
  }
  .benefit-cards {
    flex-wrap: wrap;
  }
  .benefit-card {
    margin-bottom: 40px;
  }
  .benefit-card-title {
    margin-bottom: 0px;
    height: max-content;
  }
  .landingPage__Experience {
    grid-template-columns: 1fr;
  }
  .experience-left {
    margin-left: 0;
    text-align: center;
    padding: 10px;
  }
  .landingPage__Extra {
    display: flex;
    flex-direction: column-reverse;
  }
  .extra-left {
    margin-right: 0;
  }
  .extra-right {
    padding: 10px;
    text-align: center;
  }
  .services-right h3 {
    font-size: 1.3rem;
  }
  .extra-title {
    font-size: 1.3rem;
  }
  .experience-summary,
  .extra-summary {
    font-size: 1rem;
    line-height: inherit;
  }

  .landingPage__Footer {
    padding-inline: 0;
    grid-template-columns: 1fr;
    padding: 20px;
  }
  .footer-center {
    /* grid-column: 1/4; */
    margin: 20px 0;
  }
  .footer-links {
    font-size: 0.8rem;
  }
  .footer-social a {
    margin: 0 auto;
  }
  .hero-content {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .landingPage {
    /* background-color: red; */
    max-width: 100%;
    overflow: hidden;
  }
  .landingPage__Header {
    padding-inline: 0px;
    padding-right: 10px;
  }
  .landingPage__Hero {
    padding-top: 100px;
  }
  .hero-left {
    margin-left: 40px;
  }

  .hero-content h2 {
    font-size: 1.2rem;
    margin-top: 0px;
    margin-bottom: 0;
  }
  .hero-content p {
    font-size: 0.9rem;
  }
  .benefit-card-image {
    width: 170px;
  }
  .benefit-card {
    margin-inline: 10px;
    flex: 0.3;
  }
  .benefit-card-title {
    font-size: 0.9rem;
    line-height: 16px;
    height: 45px;
  }
  .benefit-card-summary {
    font-size: 0.6rem;
    text-align: center;
  }
  .experience-left {
    margin-left: 20px;
  }
  .experience-title,
  .extra-title {
    font-size: 1.2rem;
  }
  .experience-summary,
  .extra-summary {
    font-size: 0.8rem;
  }
  .landingPage__Footer {
    padding: 40px 20px;
  }
  .footer-left {
    margin-right: 10px;
    font-size: 10px;
  }
  .footer-links {
    margin-top: 20px;
    margin-right: 15px;
    font-size: 0.8rem;
  }
  .services-right {
    padding-right: 10px;
  }
}
