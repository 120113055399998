.mapEvaluator__Modal .map-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
}

.mapEvaluator__Modal .modal__Close {
  background-color: var(--orange);
  padding: 10px 20px;
  display: block;
  max-width: max-content;
  margin-left: auto;
  font-size: 1.5rem;
  color: white;
  border-radius: 10px;
}

.mapEvaluator__Modal .mapEvaluator__Content {
  padding: 20px;
}
.mapEvaluator__Modal .mapEvaluator__Content h2 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.mapEvaluator__Modal .formik-form {
  /* max-height: 400px;
  overflow: scroll;
  overflow-x: hidden; */
}
.mapEvaluator__Modal .formik-participant {
  border: 1px solid var(--grey);
  padding: 10px 20px;
  width: 300px;
  margin-bottom: 20px;
}
.mapEvaluator__Modal .field-array {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 0.2fr;
  row-gap: 15px;
  max-height: 400px;
  overflow-y: scroll;
}
.mapEvaluator__Modal .formik-text-field {
  border: 1px solid var(--grey);
  padding: 10px 15px;
  margin-right: 10px;
}
.mapEvaluator__Modal .modal-remove-field {
  max-width: max-content;
}
.mapEvaluator__Modal .modal-remove-field svg {
  color: var(--orange);
}
.mapEvaluator__Modal .add-new {
  background-color: var(--red);
  /* align-self: center; */
  padding: 10px 40px;
  color: white;
  display: block;
  margin: 10px 0 20px;
  /* grid-column: 1/4; */
  font-weight: 500;
  max-width: max-content;
  border-radius: 10px;
}
.mapEvaluator__Modal .modal-submit {
  width: max-content;
  display: block;
  margin: 10px auto;
  background-color: var(--gold);
  color: white;
  padding: 10px 40px;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 5px;
}
