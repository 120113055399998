.evaluator-response {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  align-items: center;
  max-width: 800px;
}
.evaluator-response h2 {
  font-weight: 600;
  font-size: 1.2rem;
}
.evaluator-box {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  border: 1px solid var(--grey);
  /* height: 100px; */
}
.evaluator-box > h3 {
  padding-top: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  opacity: 0.7;
}
.evaluator-box > p {
  /* font-size: 1.4rem; */
}
.evaluator-box > p {
  padding-bottom: 20px;
}

.evaluator-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 10px;
  text-align: center;
  border: 1px solid var(--ash);
  padding: 10px;
  font-weight: 600;
  opacity: 0.7;
}
.row-header {
  margin-top: 20px;
  border: 1px solid var(--gold);
}
.evaluator-row p:first-child {
  text-align: left;
}

@media screen and (max-width: 768px) {
  .evaluator-response {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .evaluator-response h2 {
    grid-column: 1/5;
    text-align: center;
    margin-bottom: 15px;
  }
  .evaluator-box {
    text-align: center;
    height: 140px;
  }
  .evaluator-box > p {
    width: inherit;
  }
  .evaluator-row progress {
    max-width: 100px;
    background-color: brown;
    color: yellow;
    display: none;
  }
  .evaluator-rows {
    padding-left: 10px;
    padding-right: 10px;
  }
  .evaluator-row {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .evaluator-row p {
    font-size: 0.8rem;
  }
}
