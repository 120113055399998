.modal__BasicModal .modal__Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
}
.modal__BasicModal .modal__Close {
  background-color: var(--orange);
  padding: 10px 20px;
  display: block;
  max-width: max-content;
  margin-left: auto;
  font-size: 1.5rem;
  color: white;
  border-radius: 10px;
}
.modal__BasicModal .modal__Content {
  padding: 20px;
}
.modal__BasicModal .modal__Content h2 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.modal__BasicModal .modal-field-text {
  border: 1px solid var(--grey);
  padding: 5px;
  /* margin-bottom: 20px; */
  margin-inline: 10px;
}
.modal__BasicModal .modal-remove-field svg {
  font-size: 1.5rem;
  color: var(--orange);
}
.modal__BasicModal .modal-add-new,
.modal__BasicModal .modal-submit {
  background-color: var(--orange);
  padding: 5px 20px;
  border-radius: 10px;
  color: white;
}
.modal__BasicModal .modal-submit {
  display: block;
  max-width: max-content;
  margin: 10px auto;
  background-color: var(--gold);
  padding: 10px 20px;
  border-radius: 5px;
}
.modal__BasicModal .modal-field {
  display: grid;
  grid-template-columns: repeat(2, 1fr) repeat(4, 200px) repeat(2, 1fr) 0.6fr;
  align-items: center;
  margin: 20px 0px 40px;
}
.modal__BasicModal .participant-field-array {
  max-height: 400px;
  overflow-y: scroll;
}
