.dashboard {
  background-color: var(--grey-bg);
  padding-bottom: 20px;
}
.dashboard__Title {
  margin: 20px 20px 10px;
  font-size: 1.5rem;
  font-weight: 600;
}
.dashboard__Title > span {
  color: var(--gold);
}
.dashboard__Content {
  margin-left: 40px;
}
.dashboard__Notification {
  display: grid;
  grid-template-columns: 1fr 11fr;
  align-items: center;
  column-gap: 10px;
  max-width: 1000px;
  background-color: var(--pink);
  padding: 10px;
  font-weight: 600;
}
.notify-icon,
.notify-text > span {
  color: red;
}
.notify-icon {
  align-self: flex-end;
  justify-self: end;
}
.dashboard__Statistics {
  margin-top: 20px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: 3fr 1.2fr 1.2fr;
  column-gap: 20px;
  max-width: 1000px;
}
.dashboard-participants {
  text-align: center;
}

.stats-left {
}
.dashboard-participants {
  background-color: white;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: var(--grey);
  font-weight: 600;
}
.dashboard-participants h2 {
}
.dashboard-reports {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}
.report h4:first-child {
}
.report h4:nth-child(2) {
  color: var(--grey);
  font-weight: 600;
  font-size: 1.5rem;
}
.report {
  background-color: white;

  text-align: center;
  height: 120px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1rem;
  line-height: 40px;
}
.stats-right {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 70px;
  background-color: white;
  padding: 10px;
  font-size: 1.2rem;
}
.dashboard__Reports {
  margin-top: 50px;
}
.dashboard__Report {
  max-width: 1000px;
}
.report-header {
  margin-bottom: 20px;
  background-color: white;
  /* padding: 20px; */
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--grey);
  display: flex;
  align-items: center;
  width: 100%;
}
.report-icon-arrow {
  /* color: white; */
  /* margin-right: px; */
}
.report-icon-arrow svg {
  font-size: 80px;
  color: black;
  /* border: 1px solid green; */
}
.report-bodyy {
  display: grid;
  grid-template-columns: 1.5fr 1.2fr 1fr 1fr 1fr;
  column-gap: 10px;
  background-color: white;
  border-radius: 5px;
  padding: 10px 20px 0px;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}
.report-name {
  text-align: left;
}

.report-downloadd {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  align-self: left;
  cursor: pointer;
}
.report-iconn > svg {
  font-size: 22px;
  color: var(--green);
}
.report-downloadd button {
  color: var(--orange);
}
.dashboard-accordion {
  max-width: 1000px !important;
  background-color: transparent !important;
}

.report-not-ready {
  cursor: not-allowed;
}
.disabled-color {
  color: var(--ash) !important;
}

@media screen and (max-width: 600px) {
  .notify-icon {
    align-self: center;
  }
  .dashboard__Content {
    margin-left: 10px;
  }
  .dashboard__Statistics {
    /* grid-template-columns: 1fr; */
  }
  .stats-left {
    grid-column: 1/4;
  }
  .stats-right {
    display: grid;
    grid-row: 2/3;
    grid-column: 1/2;
    margin-top: 20px;
  }
  .stats-right:nth-child(2) {
    grid-column: 2/4;
  }
  .report-bodyy {
    font-size: 0.7rem;
    padding-inline: 0;
    column-gap: 1px;
  }
  .report-accordion-details,
  .report-accordion-summary {
    padding-inline: 0 !important;
  }
}
