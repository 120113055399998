.task {
  border: 1px solid var(--ash);
  padding-inline: 5px;
}
.task__Card {
  display: grid;
  grid-template-columns: 1fr 5fr 1.5fr;
  grid-column-gap: 15px;
}

.task__Box {
  /* flex: 0.15; */
  position: relative;
  /* border: 1px solid black; */
  /* width: 50px; */
}
.task__Sn {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  /* height: 1000px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: azure;
  background-color: var(--orange);
  font-weight: 600;
}
.task__Content {
  flex: 0.7;
  padding: 5px 0px;
  text-align: center;
  /* border: 1px solid green; */
}
.task__Action {
  font-weight: 700;
}
.task__Todo {
  color: var(--grey);
}
.task__Link {
  flex: 0.2;
  position: relative;
  width: 100%;
  display: block;
  background: var(--gold);
  height: 90%;
  margin: auto auto;
  border-radius: 5px;
}
.task-link {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--gold);

  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  /* height: 100%; */
  justify-content: center;
  align-items: center;

  align-items: center; /* max-width: 100px; */
  padding: 0px 7px;
  line-height: 16px;
}

@media screen and (min-width: 400px) {
  .task {
    max-width: 900px;
    padding: 10px;
  }
  .task__Box {
    /* width: 160px !important; */
    /* height: 40px; */
  }
  .task__Sn {
    /* height: 40px; */
  }
  .task__Content {
    font-size: 20px;
    height: 90%;
  }
  .task-link {
    display: flex;
    width: inherit;
    padding-inline: 5px 14px;
    position: relative;
  }
  .task__LinkLabel {
    margin-left: 10px;
  }
}

@media screen and (min-width: 400px) {
  .task {
    max-width: 900px;
    padding: 10px 20px;
  }
  .task__Box {
    width: 100%;
  }
  .task__Content {
    font-size: 20px;
  }
  .task-link {
    display: flex;
    width: inherit;
    padding-inline: 28px;
    position: relative;
  }
  .task__LinkLabel {
    margin-left: 10px;
  }
}
@media screen and (max-width: 500px) {
  .task__Card {
    column-gap: 2px;
    /* align-items: center; */
    grid-template-columns: 4fr 1.5fr;
  }
  .task__Box {
    display: none;
  }
  .task .task__Action {
    font-size: 0.7rem;
    /* border: 1px solid salmon; */
  }
  .task__Link {
    height: auto;
    /* border-radius: 0%; */
    padding: 10px 4px;
    /* margin-left: 4px; */
    display: flex;
  }
  .task__LinkIcon {
    display: none;
  }
  .task__LinkIcon svg {
    font-size: 0.3rem;
    text-align: center;
  }

  .task__LinkLabel {
    /* font-size: 0.6rem; */
    padding: 0;
    text-align: center;
  }
  .task-link {
    /* line-height: 15px; */
    align-items: center;
    justify-content: center;
  }
}
