.admin-dashboard__Options {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1.2fr;
}
.admin-options-left {
  /* padding: 10px; */
  height: 50px;
}
.admin-select-month {
  border: 1px solid var(--grey);
  outline: none;
  padding: 10px;
  height: 100%;
}
.admin-option-button {
  background-color: var(--gold);
  color: white;
  height: 100%;
  padding-inline: 20px;
}
.admin-options-right {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr;
  align-items: center;
  column-gap: 10px;
  padding-right: 20px;
}
.admin-option-create {
  color: var(--gold);
  text-align: right;
}
.admin-option-create svg {
  font-size: 3rem;
}
.admin-option-box {
  font-size: 0.9rem;
  background-color: var(--orange);
  border-radius: 5px;
  padding: 5px;
  color: white;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-dashboard__Statistics {
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  column-gap: 20px;
  text-align: center;
  margin-top: 50px;
}
.stat-participant {
  box-shadow: 1px 1px 5px 2px rgba(95, 94, 94, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
}
.stat-participant h3 {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 1.4rem;
}
.stat-participant h2 {
  font-weight: 600;
  font-size: 2.4rem;
}
.stat-participant-flex {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
}

.stat-participant-box {
  /* border: 1px solid var(--grey); */
  margin: 10px;
  margin-top: 30px;
}
.stat-participant-box h6 {
  font-size: 1rem;
  font-weight: 600;
}
.stat-participant-box h5 {
  font-size: 1.1rem;
}
.admin-stat-progress {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* margin-top: 20px; */
  column-gap: 15px;
  font-size: 0.8rem;
}
.stat-progress-box {
  box-shadow: 1px 1px 4px 1px rgba(95, 94, 94, 0.2);
  border-radius: 10px;
  padding: 20px 10px;
  height: max-content;
}
.stat-progress-box h6 {
  font-size: 0.7rem;
  height: 52px;
  font-weight: 600;
  /* margin-bottom: 10px; */
}
.stat-progress-box p {
  margin-top: 10px;
}
.admin-dashboard__Table {
  margin-top: 30px;
  padding-bottom: 20px;
}

@media screen and (max-width: 500px) {
  .admin-dashboard__Options {
    grid-template-columns: 1fr;
  }
  .admin-options-left {
    margin-bottom: 10px;
  }

  .admin-dashboard__Statistics {
    grid-template-columns: 1fr;
  }
  .admin-stat-progress {
    grid-template-columns: repeat(3, 1fr);
  }
  .stat-progress-box {
    margin-top: 10px;
  }
}
