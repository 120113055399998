.tablist {
  display: flex;
  max-width: max-content;
  margin-bottom: 15px;
  cursor: pointer;
}
.tablet-tab {
  border: 1px solid var(--gold);
  padding: 10px 20px;
}
.react-tabs__tab--selected {
  background-color: var(--gold);
  color: white;
}
.participant-tab {
  /* margin-right: 20px; */
}
.table-row {
  display: grid;
  grid-template-columns: 0.35fr 1fr 1fr 1fr 0.5fr 2fr;
  text-align: center;
  overflow-wrap: anywhere;
  /* width: 10%; */
}
.development-coach-table .table-row {
  grid-template-columns: 0.35fr 1fr 1fr 1fr 0.5fr 0.1fr;
}
.table-td,
.table-data {
  padding: 10px;
  border: 1px solid var(--grey);
  border-left: none;
  border-top: none;
  max-width: 100%;
}

.table-td:first-child,
.table-data:first-child {
  border-left: 1px solid var(--grey);
}
.table-header {
  border-top: 1px solid var(--grey);
  background-color: var(--table-header-bg);
  /* align-items: center; */
  /* color: white; */
}

.table-row:nth-child(odd) {
  background-color: var(--table-header-bg);
}
.table-sub-td {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  /* justify-self: left;
  width: 100%; */
  text-align: left;
  /* display: flex; */
}
.tab-table-action {
  border: 1px solid var(--grey);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 15px;
}
.table-download-icon svg {
  color: var(--gold);
}
.table-download {
  font-size: 0.5rem;
  color: var(--gold);
  text-decoration: underline;
  font-weight: 600;
}
.table-data-boxes {
  display: flex;
  flex-wrap: wrap;
}
.data-box {
  padding: 10px 20px;
  background-color: var(--gold);
  color: white;
  margin: 1px;
}
@media screen and (max-width: 1024px) {
  .table-row {
    grid-template-columns: 50px 125px 250px 125px 100px 150px;
  }
  .participant-table {
    max-width: 100vw;
    overflow: scroll;
  }
  .table-paginate {
    max-width: 100vw;
    overflow: scroll;
  }
  .table-rows,
  .table-header {
    min-width: 800px;
    align-items: center;
  }
  .table-sub-td {
    grid-template-columns: 1fr;
  }

  .tabs {
    max-width: "100%";
  }
}
