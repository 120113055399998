.assessment {
  padding-bottom: 20px;
}
.assessment__Title {
  text-align: center;
  margin: 10px;
  padding: 10px;
  color: var(--white);
  background-color: var(--orange);
}

.assessment-logo {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 20px;
}

.progressbar {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  margin-right: 40px;
}
.progressbar label {
  margin-bottom: 10px;
}
.progressbar progress {
  height: 40px;
  width: 100%;
  max-width: 400px;
  border: 1px solid var(--grey);
}

.progressbar progress::-webkit-progress-bar {
  /* style rules */
  background-color: white;
  /* background-color: var(--gold); */
}
.progressbar progress::-webkit-progress-value {
  /* style rules */
  background-color: var(--gold);
}
.progressbar progress::-moz-progress-bar {
  /* style rules */
  background-color: rgb(17, 220, 75);
}

.assessment__Form {
  border: 1px solid var(--grey);
  padding: 30px;
  margin: 30px;
  border-radius: 30px;
  max-width: 1000px;
}

.instruction-title {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.instruction-title span {
  color: var(--gold);
}
.assessment-instruction > p {
  margin-bottom: 15px;
}
.score-title {
  background-color: var(--gold);
}
.score {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  text-align: center;
  border: 1px solid var(--gold);
  border-top: none;
  display: none;
}
.score p {
  padding: 10px;
}
.score p:first-child {
  text-align: left;
  border-right: 1px solid var(--gold);
}
.assessment__Questions {
  margin-top: 20px;
}
h2.assessment-title {
  margin: 20px 10px;
  color: var(--gold);
  font-weight: 600;
  text-transform: capitalize;
}

.questions {
  display: grid;
  /* row-gap: 10px; */
}
.question {
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  border: 1px solid var(--grey);
  border-bottom: none;
  /* padding-right: 20px; */
  background-color: var(--grey-bg);
}

.question-title {
  background-color: white;
  border: none;
}

.question:last-child {
  border-bottom: 1px solid var(--grey);
}
.question p {
  padding: 20px 10px;
}
.assessment-radio {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  column-gap: 10px;

  padding-bottom: 10px;
}
.assessment-text {
  background-color: transparent;
  margin: 10px;
  padding-inline: 10px;
  border: 1px solid var(--grey);
}

.assessment-header-label {
  text-align: center;
}
.assessment-paginate {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  column-gap: 60px;
  justify-content: center;
  align-items: center;
}
.assessment-submit-error {
  /* display: flex;
  flex-direction: column; */
  margin-bottom: 10px;
}

.assessment-next,
.assessment-back,
.assessment-submit {
  padding: 10px 40px;
  color: white;
  width: 160px;
  background-color: var(--orange);
  border-radius: 10px;
}

.assessment-back {
  background-color: var(--blue);
}
.assessment-submit {
  background-color: var(--gold);
}

@media screen and (max-width: 600px) {
  .assessment .progressbar {
    width: max-content;
  }
  .assessment-progressbar {
    width: 100px !important;
    height: 20px !important;
  }
  .assessment__Form {
    padding-inline: 10px;
    margin: 10px;
  }
  .assessment-instruction h3 {
    font-size: 1rem;
  }
  .assessment-instruction p {
    font-size: 0.8rem;
  }
  .question {
    padding-right: 0;
    grid-template-columns: 1fr;
    /* overflow-wrap: anywhere; */
  }
  .question-title {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
  }
  .question-title h2 {
    font-size: 0.8rem;
    /* text-align: center; */
  }
  .question-title div {
    font-size: 0.8rem;
  }
  .assessment-question {
    font-size: 0.8rem;
  }

  .assessment-radio {
    /* column-gap: 2px; */
    /* margin: 0 auto; */
    margin-left: 5%;
  }
  .assessment-header-label {
    text-align: left;
    margin-left: 0;
  }
}

@media screen and (min-width: 1120px) {
  .assessment-radio {
    grid-template-columns: repeat(5, 0.94fr);
  }
}
@media screen and (min-width: 1000px)  and (max-width: 1100px){
  .assessment-radio {
    grid-template-columns: repeat(5, 0.68fr);
  }
}
