.button__Link {
  flex: 0.2;
  position: relative;
  /* width: 100%; */
  display: block;
  background: var(--gold);
  color: white;
  /* height: 90%; */
  margin: auto auto;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  padding: 10px;
  padding-right: 0px;
}
.button__Link svg {
  font-size: 30px;
}
.button__LinkLabel {
  justify-self: left;
  color: white;
  /* border: 1px solid gray; */
  /* align-self: flex-start; */
}
.button__Link > a {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--gold);

  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  /* height: 100%; */
  justify-content: center;
  align-items: center;

  align-items: center; /* max-width: 100px; */
  padding: 0px 7px;
  line-height: 16px;
}

@media screen and (min-width: 400px) {
  .button__Link > a {
    display: flex;
    width: inherit;
    padding-inline: 5px 14px;
    position: relative;
  }
  .button__LinkLabel {
    /* margin-left: 10px; */
  }
}

@media screen and (min-width: 400px) {
  .button__Link > a {
    display: flex;
    width: inherit;
    padding-inline: 28px;
    position: relative;
  }
  .button__LinkLabel {
    /* margin-left: 10px; */
  }
}
