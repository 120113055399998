@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans:wght@400;500&family=Roboto+Slab:wght@500&display=swap");

:root {
  --gold: #9ea224;
  --green: #6da945;
  --orange: #f35b24;
  --yellow: #fddd97;
  --red: #ff8585;
  --pink: #f8eacc;
  --grey: #b6b6b6;
  --ash: #dcdcdc;
  --grey-bg: #f7f7f7;
  --blue: #a4a8b1;
  --service-bg: #fafbfc;
  --experience-bg: #fffcf5;
  --hero-bg: #e7f1f7;
  --footer-bg: #f8faf9;
  --table-header-bg: #eeeeee;
}

body {
  font-family: "Open Sans", sans-serif;   
}
.md-container{
   height: calc(100vh - 60px);
}
div.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  z-index: 100000000;
}
input[type="tel"]:focus{
   border:none !important;
   outline: none !important
}
 
.mobile-sidebar-header {
  @apply flex items-center
           justify-between border-b border-gray-300 px-4 py-4;
}
.mobile-sidebar-close-btn {
  @apply  box-content w-4 h-4 p-2 -my-5 -mr-2 text-black
             border-none rounded-none opacity-50 focus:shadow-none 
              focus:outline-none focus:opacity-100 hover:text-black 
              hover:opacity-75 hover:no-underline;
}
.sidebar-close {
  @apply  box-content w-4 h-4 p-2 -my-5 
           -mr-2 text-black border-none rounded-none 
           opacity-50 focus:shadow-none focus:outline-none
            focus:opacity-100 hover:text-black hover:opacity-75
           hover:no-underline;
}
.admin-form-input {
  @apply relative flex-auto min-w-0 block 
           sm:w-full w-[100%] px-3 py-5 md:py-3 text-sm font-normal text-gray-700
           bg-white bg-clip-padding border border-solid mt-1
           border-gray-300 rounded-lg transition ease-in-out m-0 
           focus:text-gray-700 focus:bg-white focus:border-blue-400
            focus:outline-none  focus:shadow-none;
}

.admin-form-input-sm {
  @apply relative flex-auto min-w-0 block 
          px-3 py-2 text-[13px] font-normal text-gray-700
          bg-white bg-clip-padding border border-solid mt-1
          border-gray-300 rounded-lg transition ease-in-out m-0 
          focus:text-gray-700 focus:bg-white focus:border-blue-400
           focus:outline-none  focus:shadow-none;
}

.navlink {
  @apply flex items-center border-b border-white  py-5 px-3;
}
.admin-form-btn {
  @apply bg-red-500 py-2 text-sm px-8 mt-8 text-white rounded-full;
}
.image-icon-wrapper {
  @apply flex items-center justify-center border 
            rounded-md border-gray-800 border-dotted
           w-[90px] h-[90px] block;
}
.upload-btn {
  @apply rounded-full inline-block px-8 bg-green-500 py-2 text-sm text-white;
}
#active-link {
  background: #f7f7f8;
}

.search-input {
  @apply relative flex-auto min-w-0 block 
          w-full px-3 py-1.5 text-sm font-normal text-gray-700
           bg-white bg-clip-padding border border-solid 
           border-gray-300 rounded-lg transition ease-in-out m-0 
           focus:text-gray-700 focus:bg-white 
            focus:outline-none  focus:shadow-none;
}
.search-btn {
  @apply inline-block px-6 py-3 bg-transparent border-t border-b border-r 
             font-medium text-xs leading-tight text-gray-400 border-gray-300
             uppercase rounded-lg          
             focus:outline-none focus:ring-0 active:bg-green-500 
             active:shadow-lg transition duration-150 ease-in-out
              flex items-center;
}

.industry-type-input {
  @apply appearance-none
    block
    w-full
    px-2
    py-3
    text-sm
    font-normal
    text-gray-700
    bg-white bg-clip-padding bg-no-repeat
    border border-solid border-gray-300
    rounded-lg
    transition
    ease-in-out
    m-0 mt-1
    focus:text-gray-700 focus:bg-white   focus:border-red-400 focus:outline-none;
}
.industry-type-input:active,
.industry-type-input:focus {
  border-color: #dfdfe2 !important;
  outline: none !important;
}

.company-list-tr {
  @apply text-[13px] text-gray-900 font-light px-4 py-3 whitespace-nowrap;
}
.admin-form-check {
  @apply
    appearance-none rounded-full h-4 w-4 border 
    border-gray-300 bg-white checked:bg-green-600 
    checked:border-green-600 focus:outline-none 
    transition duration-200 mt-1 align-top bg-no-repeat
     bg-center bg-contain float-left mr-2 cursor-pointer;
}

.admin-form-check-lg {
  @apply
   appearance-none rounded-full h-[23px] w-[23px] border 
   border-gray-300 bg-white checked:bg-green-600 
   checked:border-green-600 focus:outline-none 
   transition duration-200 mt-1 align-top bg-no-repeat
    bg-center bg-contain float-left mr-2 cursor-pointer;
}
.admin-form-switch {
  @apply appearance-none
            w-9 -ml-10  rounded-full float-left h-5 
            align-top bg-white bg-no-repeat bg-contain
            focus:outline-none cursor-pointer shadow-sm;
}
#portal {
  position: absolute;
  z-index: 100000;
  bottom: 50px;
  left: 0 !important;
  width: 300px !important;
  overflow: hidden;
}
.date-picker > * {
  font-size: 13px !important;
}
.date-picker input,
.date-picker select {
  outline: none !important;
  background-color: transparent !important;
  border: none !important;
}

.date-picker input:focus,
.date-picker select:focus {
  border: 1px solid rgb(218, 5, 5) !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  border-radius: 5px;
}

.react-datetime-picker__wrapper {
  padding: 7px 5px !important;
  border: 1px solid rgb(215, 216, 218) !important;
  border-radius: 10px !important;
  height: 50px !important;
}
.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  position: absolute !important;
  bottom: 60px !important;
}
.react-calendar__navigation__label {
  pointer-events: none !important;
  font-size: 12px !important;
}
.react-calendar__tile {
  font-size: 12px !important;
  padding: 5px !important;
  width: 7px !important;
}

.quill {
  margin-bottom: 30px;
}
.bold {
  font-weight: 600;
}
.neumorph {
  box-shadow: 2px 2px 2px white, 2px 2px 2px rgba(73, 73, 73, 0.7),
    2px 2px 2px white, 2px 2px 2px rgba(73, 73, 73, 0.7);
}
.error-message {
  color: var(--orange);
}
.disabled {
  background-color: var(--ash) !important;
  pointer-events: none;
}
.orange {
  color: var(--orange) !important;
}
.orange-bg {
  background-color: var(--orange) !important;
}
.gold {
  color: var(--gold);
}
.gold-bg {
  background-color: var(--gold) !important;
}
.modal {
  position: fixed !important;
}
.pagination > * {
  background: transparent !important;
}
.yellow {
  color: var(--yellow);
}
.yellow-bg {
  /* background-color: var(--yellow) !important; */
  background-color: rgb(243, 243, 79) !important;
}
.red {
  color: var(--red);
}
.evaluator-drop-start{
   @apply          
   font-medium  
   leading-tight   
   flex
   items-center
   whitespace-nowrap
}
.evaluator-dd-menu{
   @apply min-w-max
   absolute
   hidden
   bg-white
   text-base
   z-50
   float-left
   pb-2
   list-none
   text-left
   rounded-lg
   shadow-lg
   border-l-2 border-green-small
   h-[140px]
   overflow-y-auto
   mt-1
   hidden   
   m-0
   bg-clip-padding
   border-none
}
.evaluator-dd-menu-btn{
    @apply text-sm
    py-2
    px-4
    font-normal
    block
    w-full
    whitespace-nowrap
    bg-transparent
    text-gray-700
    hover:bg-gray-100
    text-xs
    py-2
    px-4
    font-normal     
}
.collapse{
  visibility: visible !important;
}  
  @media screen and (max-width: 500px){
    .m-hidden{
       display: none;       
    }
    div::after,div::before{
       font-size: 13px !important;
       text-transform: capitalize !important;
    }
  }
 
.participant-table{
   overflow-x: scroll !important;
}

/* Style the scrollbar */
::-webkit-scrollbar {
	/* Set the width of the scrollbar */
	width: 5px !important;
	height: 5px !important;   
  }
  
  /* Style the scrollbar track */
  ::-webkit-scrollbar-track {
	/* Set the background color of the scrollbar track */
	background-color: white;
  border: 1px solid #6da945;
  }
  
  /* Style the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
	/* Set the color of the scrollbar thumb */
	background-color: #9ab767;
	border-radius: 20px;
	width: 8px !important;
  }
  
  /* Style the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
	/* Set the color of the scrollbar thumb on hover */
	background-color:  #9ab767;
  }

   @media only screen and (max-width: 500px){
	::-webkit-scrollbar {
		/* Set the width of the scrollbar */
		width: 2px !important;
		margin-top: 5px;
	  }
   }
  
  