.modal {
  position: relative;
}
.modal-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--orange);
  color: white;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: 5px;
  outline: none;
}
.modal-button:hover {
  background-color: var(--orange);
}
@media screen and (max-width: 600px) {
  .modal__Box {
    max-width: 100% !important;
  }
}
